import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CContainer, CAccordion, CButton } from "@coreui/react";
import axios from "../api/axios";
import { CSpinner } from "@coreui/react";

import SubCategoryItem from "./SubCategoryItem";

import { ERRORS, REGISTERED_SERVICE, BOOKING_ERROR } from "../ERROR";
import useError from "../hooks/useError";

const SubCategory = ({ services }) => {
  const navigate = useNavigate();

  const [selectedItems, setSelectedItems] = useState([]);
  const [isChecking, setIsChecking] = useState(false);

  const { showError } = useError();

  const handleCheckin = async () => {
    try {
      setIsChecking(true);
      await axios.post("/dailyservice/store", {
        data: selectedItems,
      });
      showError(REGISTERED_SERVICE, ERRORS.SUCCESS);
      navigate("/");
    } catch (error) {
      showError(BOOKING_ERROR, ERRORS.ERROR);
    } finally {
      setIsChecking(false);
    }
  };

  console.log(selectedItems);

  return (
    <>
      <CContainer className="mt-4">
        <CAccordion>
          {services?.map((serviceItem, index) => {
            return (
              <SubCategoryItem
                serviceItem={serviceItem}
                index={index}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                key={index}
              />
            );
          })}
        </CAccordion>

        {selectedItems.length > 0 && (
          <div className="checkin-button mt-3">
            <CButton disabled={isChecking} onClick={handleCheckin} color="dark">
              {isChecking ? <CSpinner size="sm" /> : "Check In"}
            </CButton>
          </div>
        )}
      </CContainer>
    </>
  );
};

export default SubCategory;
