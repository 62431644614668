import React, { useContext, useEffect, useState } from "react";

import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import DataContext from "../components/Datacontext";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CButton,
  CButtonGroup,
  CDropdownMenu,
  CDropdownToggle,
  CDropdown,
  CDropdownItem,
  CNavLink,
  CNav,
  CNavItem
} from "@coreui/react";

function Transcation() {
  const { auth } = useAuth();
  const authToken = auth.access_token;
  const { store_id } = useContext(DataContext);
  const [transactionItems, setTrancationItem] = useState([]);
  const [storeItems, setStoreItem] = useState([]);
  const [storeID, setStoreID] = useState('');
  const apiURL = auth.roles === 6099 ? `/bills` : `/bills/all-bill/${store_id}`;
  useEffect(() => {
    fetchData();    
      fetchStoreData();
    
  }, []);

  const fetchData = async () => {

    try {
      const response = await axios.get(apiURL, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setTrancationItem(response.data.Bill);
    } catch (error) {
      console.error("Error fetching client list:", error);
    }
  };
  const fetchStoreData = async () => {
    try {
      if (auth.roles == 6099) {
        const response = await axios.get('/store', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setStoreItem(response.data.Store);
        console.log(storeItems);
      }
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  };
  

  // Function to calculate grand total
  const calculateGrandTotal = () => {
    let grandTotal = 0;

    // Loop through each bill
    transactionItems.forEach((bill) => {
      // Add the totalprice of each bill to grandTotal
      grandTotal += parseFloat(bill.totalprice);
    });

    return grandTotal.toFixed(2); // Format to two decimal places
  };

  const calculateServiceTotal = () => {
    let serviceTotal = 0;

    // Loop through each bill
    transactionItems.forEach((bill) => {
      // Add the totalprice of each bill to grandTotal
      serviceTotal += parseInt(bill.servicecount, 10);
    });

    return serviceTotal; // Format to two decimal places
  };
  const SearchTransaction = async (type) => {
    try {
      const response = await axios.post(
        "/bills/search",
        {
          store_id: store_id,
          dtype: type,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setTrancationItem(response.data.Bill);
    } catch (error) {
      console.error("Error fetching client list:", error);
    }
  };
  const SearchTransactionAdmin = async (type,storeid) => {

    try {
      setStoreID(storeid)
      const response = await axios.post(
        "/bills/search",
        {
          store_id: storeid,
          dtype: type,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setTrancationItem(response.data.Bill);
    } catch (error) {
      console.error("Error fetching client list:", error);
    }
  };

  return (
    <CCard>
      <CCardHeader>
        <div className="d-flex justify-content-between">
          <h3>Transcations ({transactionItems.length})</h3>
          <CNav>
          <CNavItem>
          {auth.roles == 6099 &&(
            <CDropdown className="mx-3">
              <CDropdownToggle color="dark">Select Store</CDropdownToggle>
              <CDropdownMenu>
                {
                  storeItems && storeItems.length>0 && (
                    storeItems.map((item, index) => ( 
                      item.id != 0 && ( 
                      <CDropdownItem  key={index} onClick={() => SearchTransactionAdmin('Today',item.id)}>{item.name}</CDropdownItem>
                      )
                      )
                    ))
                }
                
               
              </CDropdownMenu>
            </CDropdown>
          )}
          </CNavItem>
          <CNavItem>
            <CButtonGroup>
               {auth.roles != 6099 ?(
                <>
                <CButton
                  href="#"
                  onClick={() => SearchTransaction("Month")}
                  className="ml-1"
                  color="dark"
                >
                  1M
                </CButton>
                <CButton
                  href="#"
                  onClick={() =>SearchTransaction("Quater")}
                  className="ml-1"
                  color="dark"
                >
                  3M
                </CButton>
                <CButton
                  href="#"
                  onClick={() =>SearchTransaction("Year")}
                  className="ml-1"
                  color="dark"
                >
                  1Y
                </CButton>
              </>
               ):(
                <>
                <CButton
                  href="#"
                  onClick={() => SearchTransactionAdmin("Month",storeID)}
                  className="ml-1"
                  color="dark"
                >
                  1M
                </CButton>
                <CButton
                  href="#"
                  onClick={() =>SearchTransactionAdmin("Quater",storeID)}
                  className="ml-1"
                  color="dark"
                >
                  3M
                </CButton>
                <CButton
                  href="#"
                  onClick={() =>SearchTransactionAdmin("Year",storeID)}
                  className="ml-1"
                  color="dark"
                >
                  1Y
                </CButton>
              </>
               )}
            </CButtonGroup>
          </CNavItem>
        </CNav>
          
        </div>
      </CCardHeader>
      <CCardBody>
        <table className="table">
          <thead>
            <tr>
              <th scope="col"> Sno </th>
              <th scope="col"> Client </th>
              <th scope="col"> Services </th>
              <th scope="col"> Service Count </th>

              <th scope="col"> Price </th>
            </tr>
          </thead>
          <tbody>
            {transactionItems && transactionItems.length > 0 ? (
              transactionItems.map((item, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{item.f_name + " " + item.l_name}</td>
                  <td>
                    {item.services.map((sitem, sitemindex) => (
                      <div key={sitemindex}>
                        <small>{sitem.subcategory}</small>
                      </div>
                    ))}
                  </td>
                  <td>{item.servicecount}</td>

                  <td>${item.totalprice}</td>
                </tr>
              ))
            ) : (
              <tr>
                <th colSpan={5} scope="row">
                  No record found
                </th>
              </tr>
            )}
            {transactionItems.length > 0 && (
              <tr>
                <td colSpan={3}></td>

                <td>
                  <strong>Total Services: {calculateServiceTotal()}</strong>
                </td>
                <td>
                  <strong>Grand Total: ${calculateGrandTotal()}</strong>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </CCardBody>
    </CCard>
  );
}

export default Transcation;
