import { useState, useEffect } from "react";
import { CButton, CFormInput, CSpinner } from "@coreui/react";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";

import {
  ERRORS,
  DISCOUNT_EXCEED,
  DISCOUNT_EXCEED_PRICE,
  TABLE_UPDATED,
  TABLE_UPDATE_FAILED,
  CORRECT_DISCOUNTS,
} from "../ERROR";
import useError from "../hooks/useError";

function CompletedList({ services }) {
  const { auth } = useAuth();
  const [serviceData, setServiceData] = useState(services);
  const [isSaving, setIsSaving] = useState(false);

  const { showError } = useError();

  const [discounts, setDiscounts] = useState(() => {
    return serviceData.map((service, index) => ({
      id: index,
      amount: service.discount,
      percentage: service.percentage,
      isValid: true,
    }));
  });

  const [totalPrice, setTotalPrice] = useState(() =>
    serviceData.reduce(
      (accum, service) =>
        parseFloat(service.discount) <= 0
          ? (accum += service.price)
          : (accum += parseFloat(service.discount)),
      0
    )
  );

  const access_token = auth.access_token;

  const [isTableSaved, setIsTableSaved] = useState(() => {
    return serviceData
      .reduce((ids, service) => [...ids, service.bill_id], [])
      .includes("0");
  });

  const [rowEditing, setRowEditing] = useState(() => {
    return serviceData.map((service, serviceIndex) => ({
      id: serviceIndex,
      editing: false,
    }));
  });

  const [discErrors, setDiscErrors] = useState(() => {
    return serviceData.map((service, serviceIndex) => ({
      id: serviceIndex,
      error: null,
    }));
  });

  useEffect(() => {
    const totalPrice = calculateTotalPrice();
    setTotalPrice(totalPrice);
  }, [discounts, rowEditing, isTableSaved]);

  const calculateDiscountPercentage = (e, price, serviceIndex, id) => {
    let digitOnlyDiscount = e.target.value.replace(/[^\d.]+/g, (match) =>
      match === "." ? match : ""
    );
    const percentage = (
      digitOnlyDiscount > 0 ? ((price - +digitOnlyDiscount) / price) * 100 : 0
    ).toFixed(2);
    const updatedDiscount = discounts.map((discount) =>
      discount.id === serviceIndex
        ? {
            ...discount,
            amount: digitOnlyDiscount,
            percentage,
            isValid: !(percentage > 20) && !(percentage < 0),
          }
        : discount
    );

    if (percentage > 20) {
      setDiscErrors((prev) =>
        prev.map((item) =>
          item.id === serviceIndex ? { ...item, error: DISCOUNT_EXCEED } : item
        )
      );
    }
    if (percentage < 0) {
      setDiscErrors((prev) =>
        prev.map((item) =>
          item.id === serviceIndex
            ? { ...item, error: DISCOUNT_EXCEED_PRICE }
            : item
        )
      );
    }

    if (percentage <= 20 && percentage >= 0) {
      setDiscErrors((prev) =>
        prev.map((item) =>
          item.id === serviceIndex ? { ...item, error: null } : item
        )
      );
    }

    setDiscounts(updatedDiscount);
  };

  const calculateTotalPrice = () => {
    const totalAmount = discounts
      .map((discount, index) =>
        !discount.isValid || +discount.amount === 0
          ? serviceData[index].price
          : discount.amount
      )
      .reduce((accum, amount) => (accum += +amount), 0)
      .toFixed(2);

    return totalAmount;
  };

  const handleUpdateBillTable = () => {
    const updatedList = serviceData.map((service, serviceIndex) => ({
      ...service,
      total:
        discounts[serviceIndex].isValid && discounts[serviceIndex].amount > 0
          ? discounts[serviceIndex].amount
          : service.price,
    }));

    try {
      setIsSaving(true);
      if (discounts.map((discount) => discount.isValid).includes(false)) {
        showError(CORRECT_DISCOUNTS, ERRORS.WARNING);
        return;
      } else {
        serviceData.forEach((service, serviceIndex) => {
          axios.put(
            `/dailyservice/update/${service.id}`,
            {
              discount: discounts[serviceIndex].amount,
              percentage: discounts[serviceIndex].percentage,
            },
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          );
        });
      }

      if (discounts.map((discount) => discount.isValid).includes(false)) {
        return;
      } else {
        axios
          .post(
            `/bill/store`,
            {
              servicelist: JSON.stringify(updatedList),
              totalprice: totalPrice,
            },
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
          .then((response) => {
            const updateBill = response.data.Bill;

            if (updatedList.length > 0) {
              const updateBillIdPromises = updatedList.map((el) =>
                axios.put(
                  `/dailyservice/update/${el.id}`,
                  {
                    bill_id: updateBill.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${access_token}`,
                    },
                  }
                )
              );

              // Use Promise.all to send all requests and wait for them to complete
              Promise.all(updateBillIdPromises)
                .then(() => {
                  setIsTableSaved(() => {
                    serviceData
                      .reduce((ids, service) => [...ids, service.bill_id], [])
                      .includes("0");
                  });
                  isTableSaved && setIsSaving(false);
                  showError(TABLE_UPDATED, ERRORS.SUCCESS);
                })
                .catch((error) => {
                  // Handle errors here if any request fails

                  showError(TABLE_UPDATE_FAILED, ERRORS.ERROR);
                });
            }
          });

        setRowEditing(() => {
          return serviceData.map((_, serviceIndex) => ({
            id: serviceIndex,
            editing: false,
          }));
        });
      }
    } catch (err) {
      showError(TABLE_UPDATE_FAILED, ERRORS.ERROR);
    } finally {
      setIsSaving(false);
    }
  };

  const handleFieldEditing = (id) => {
    setRowEditing(() =>
      rowEditing.map((row) => (row.id === id ? { ...row, editing: true } : row))
    );
  };

  return (
    <div>
      +
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Service Name</th>
            <th scope="col">Subcategory</th>
            <th scope="col">Price</th>
            <th scope="col">Discounted Amount</th>
            <th scope="col">Discount %</th>
            {!isTableSaved && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {serviceData &&
            serviceData.map((service, serviceIndex) => (
              <tr key={serviceIndex}>
                <th scope="row">{serviceIndex + 1}</th>
                <td>
                  <strong>{service.service}</strong>
                </td>
                {service?.subcategory ? (
                  <td>{service?.subcategory}</td>
                ) : (
                  <td></td>
                )}

                <td>${service.price}</td>

                {isTableSaved || rowEditing[serviceIndex].editing ? (
                  <td>
                    <CFormInput
                      id={`discount-${serviceIndex}`}
                      type="text"
                      placeholder="Discount"
                      autoComplete="off"
                      onChange={(e) =>
                        calculateDiscountPercentage(
                          e,

                          service.price,
                          serviceIndex,
                          service.id
                        )
                      }
                      value={discounts[serviceIndex].amount}
                      required
                    />
                    {discErrors[serviceIndex].error && (
                      <span className="text-danger">
                        {discErrors[serviceIndex].error}
                      </span>
                    )}
                  </td>
                ) : (
                  <td>{discounts[serviceIndex].amount}</td>
                )}
                <td>
                  {discounts[serviceIndex].isValid
                    ? discounts[serviceIndex].percentage
                    : "Invalid"}
                </td>
                {rowEditing[serviceIndex].editing ? (
                  <td>
                    <CButton
                      onClick={() => {
                        handleUpdateBillTable();
                        setRowEditing(() =>
                          rowEditing.map((row) =>
                            row.id === serviceIndex &&
                            !discounts[serviceIndex].isValid === false
                              ? { ...row, editing: false }
                              : row
                          )
                        );
                      }}
                      color="dark"
                      size="sm"
                    >
                      Save
                    </CButton>
                  </td>
                ) : !isTableSaved ? (
                  <td>
                    <CButton
                      onClick={() => handleFieldEditing(serviceIndex)}
                      color="dark"
                      size="sm"
                    >
                      Edit
                    </CButton>
                  </td>
                ) : null}
              </tr>
            ))}
        </tbody>
      </table>
      <div>
        <p>
          <strong>Total Price: ${totalPrice}</strong>
        </p>
      </div>
      {isTableSaved && (
        <CButton
          disabled={isSaving}
          onClick={handleUpdateBillTable}
          color="dark"
        >
          {isSaving ? <CSpinner size="sm" /> : "Save Bill Table"}
        </CButton>
      )}
      {!isTableSaved && rowEditing.every((row) => row.editing === true) && (
        <CButton
          disabled={isSaving}
          onClick={handleUpdateBillTable}
          color="dark"
        >
          {isSaving ? <CSpinner size="sm" /> : "Update Table"}
        </CButton>
      )}
    </div>
  );
}

export default CompletedList;
