// Booking.js
import React, { useEffect, useState, useContext } from "react";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import {
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CAccordion,
  CForm,
  CFormInput,
  CButton,
  CCard,
  CCardBody,
  CCardTitle,
  CCardText,
  CRow,
  CContainer,


} from "@coreui/react";
import DataContext from "../components/Datacontext";
import useError from "../hooks/useError";
import { ERRORS, NO_DATA_FOUND } from "../ERROR";

const Reports = () => {
  const { auth } = useAuth();
  const { store_id } = useContext(DataContext);
  const access_token = auth.access_token;
  const [dateFrom, setDatefrom] = useState("");
  const [dateTo, setDateto] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [grandTotalRecords, setGrandTotalRecords] = useState(0);

  const { showError } = useError();


  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    // Handle form submission here, e.g., sending data to a server
    try {
      await axios
      .post ("/bills/search",
      {
        store_id: store_id,
        dto:dateTo,
        dfrom:dateFrom,
      }, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((response) => {
          setData(response.data.Bill);
          setLoading(false);
         

         
        })
        .catch(() => {
          showError(NO_DATA_FOUND, ERRORS.ERROR);
          setLoading(false);
        });

        
    } catch (error) {}
  };
  function calculatesales(){
    let grandTotal = 0;
    for (const month in data) {
     
      grandTotal += data[month].length;
    }
   return grandTotal;
  }
function calculateGrossSales(){
    let grandTotal = 0;
    for (const month in data) {     
      for (const record of data[month]) {
        grandTotal += parseFloat(record.totalprice);
      }
    }
   return grandTotal.toFixed(2);
  }

  function averageSales(){
    const average = calculateGrossSales()/calculatesales();
    return average.toFixed(2);
  }

  const formatServices = (services) => {
    if (Array.isArray(services)) {
      return services.join(", ");
    }
    // Handle the case where services might be a JSON string
    try {
      const parsedServices = JSON.parse(services);
      if (Array.isArray(parsedServices)) {
        return parsedServices.join(", ");
      }
    } catch (error) {}
    return "N/A";
  };

  function formatDate(inputDate) {
    const options = {
      weekday: "long",     
      month: "long",
      day: "numeric",
    };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  }
  function calculateTotalPrice(billDetails){
    let totalPrice = 0;

    billDetails.forEach(item => {
      totalPrice += parseFloat(item.totalprice) || 0;
    });

    return totalPrice.toFixed(2);
  };


  const formatTime = (time) => {   
    const dateObject = new Date(time);

    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return dateObject.toLocaleTimeString([], options);
  };

  return (
    <div>
      <div className="justify-content-between align-item-center d-flex mb-3">
        <h2>Transactions Report</h2>
        <CForm className="d-flex" onSubmit={handleSearchSubmit}>
          <CFormInput
            type="date"
            className="me-2"
            placeholder="date from"
            value={dateFrom}
            onChange={(e) => setDatefrom(e.target.value)}
            required
          />
          <CFormInput
            type="date"
            className="me-2"
            placeholder="date to"
            value={dateTo}
            onChange={(e) => setDateto(e.target.value)}
            required
          />
          <CButton type="submit" color="dark" variant="outline">
            Search
          </CButton>
        </CForm>
      </div>
      {loading ? (
        <p>Select the date range to view the report</p>
      ) : (
        <>
        <CContainer>
        <CRow className="mb-3">
        <CCard className="col-md-4">
          <CCardBody>
            <CCardTitle>Sales</CCardTitle>
            <h1 fontSize={'22px'}>
             {calculatesales()}
            </h1>
          </CCardBody>
        </CCard>
        <CCard className="col-md-4">
          <CCardBody>
            <CCardTitle>Gross Sales</CCardTitle>
            <h1>
             ${calculateGrossSales()}
            </h1>
          </CCardBody>
        </CCard>
        <CCard className="col-md-4">
          <CCardBody>
            <CCardTitle>Average Sales</CCardTitle>
            <h1>
             ${averageSales()}
            </h1>
          </CCardBody>
        </CCard>
        </CRow>
        </CContainer>
          {Object.keys(data).map((date, key) => (
            
            <React.Fragment key={date}>              
              <CAccordion activeItemKey={key}>
                <CAccordionItem  className="mb-3">
                    <CAccordionHeader>{date}</CAccordionHeader>
                    <CAccordionBody>
                      <table className="table">
                        {/* Table headers */}
                        <thead>
                          <tr>
                            <th>ID</th>  
                            <th>Client Name</th>
                            <th>Service Count</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Price</th>
                          </tr>
                        </thead><tbody>
                       {data[date].map((bill, index) => (
                
                        
                          <tr key={index}>
                            <td>{index + 1}</td>                           
                            <td>{bill.f_name + ' ' + bill.l_name}</td> 
                            <td>{bill.servicecount}</td>
                            <td>{formatDate(bill.created_at)}</td>
                            <td>{formatTime(bill.created_at)}</td>
                            <td>${bill.totalprice}</td>
                          </tr> 
                ))}
                <tr>
    <td colSpan={6}><strong className="text-end d-block mr-2">Total: ${calculateTotalPrice(data[date])}</strong></td>
  </tr>
                </tbody>
               
                 </table>
                    </CAccordionBody>
                  </CAccordionItem>
              </CAccordion>
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  );
};

export default Reports;
