import React, { useContext, useEffect, useState } from "react";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import DataContext from "../components/Datacontext";
import { useParams } from "react-router-dom";
import {
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CCard,
  CCardHeader,
  CCardBody,
  CButton,
} from "@coreui/react";

function ServiceToStore() {
  const { storeid } = useParams();
  const { auth } = useAuth();
  const authToken = auth.access_token;
  const { store_id } = useContext(DataContext);
  const [serviceitems, setServiceItems] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedServiceIndexes, setCheckedServiceIndexes] = useState([]);
  console.log(storeid);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "/getall-services-category",
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      // Initialize checkedItems with the same structure as serviceitems
      const initialCheckedItems = response.data.Services.map((service) =>
        service.child.map(() => false)
      );

      setServiceItems(response.data.Services);
      setCheckedItems(initialCheckedItems);
    } catch (error) {
      console.error("Error fetching service subcategories:", error);
    }
  };

  const handleCheckboxChange = (serviceIndex, itemIndex) => {
    const updatedCheckedItems = [...checkedItems];
    updatedCheckedItems[serviceIndex][itemIndex] =
      !updatedCheckedItems[serviceIndex][itemIndex];
    setCheckedItems(updatedCheckedItems);

    // Update the checkedServiceIndexes based on the checked items
    const isServiceIndexChecked =
      updatedCheckedItems[serviceIndex].some(Boolean);
    setCheckedServiceIndexes((prevIndexes) =>
      isServiceIndexChecked
        ? [...prevIndexes, serviceIndex]
        : prevIndexes.filter((index) => index !== serviceIndex)
    );
    console.log(checkedItems);
  };

  const handleCheckAllChange = (serviceIndex) => {
    const updatedCheckedItems = checkedItems.map((service, index) =>
      index === serviceIndex
        ? service.map(() => !checkedItems[serviceIndex][0])
        : service
    );

    setCheckedItems(updatedCheckedItems);

    // Update the checkedServiceIndexes based on the checked items
    const isServiceIndexChecked =
      updatedCheckedItems[serviceIndex].some(Boolean);
    setCheckedServiceIndexes((prevIndexes) =>
      isServiceIndexChecked
        ? [...prevIndexes, serviceIndex]
        : prevIndexes.filter((index) => index !== serviceIndex)
    );
  };

  const handleSubmit = async () => {
    try {
      // Flatten the checked items array to get a list of selected items
      const service_list_id = serviceitems.flatMap((service, serviceIndex) =>
        service.child
          .filter((item, itemIndex) => checkedItems[serviceIndex][itemIndex])
          .map((selectedItem) => selectedItem?.id)
      );

      // Send a POST request to the specified URL
      const response = await axios.post(
        "store-service/store",
        { service_list_id, store_id: storeid },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      // Handle the response as needed
      console.log("POST request successful", response.data);

      // If any serviceIndex is checked, post to a different endpoint "http://localhost/store-cat-add"
      if (checkedServiceIndexes.length > 0) {
        // Create another list of selected items based on the checked serviceIndexes
        const selectedServiceItems = serviceitems
          .filter((service, serviceIndex) =>
            checkedServiceIndexes.includes(serviceIndex)
          )
          .flatMap((service) => service);
          
        // Extract only the service_list_ids from the selected items for the second endpoint
        const selectedServiceIds = selectedServiceItems.map(
          (selectedItem) => selectedItem.id
        );
        console.log(selectedServiceIds);

        // Send a POST request to the second endpoint with the selected service_list_ids
        const secondResponse = await axios.post(
          "/store-category/store",
          { service_id:selectedServiceIds,store_id: storeid },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        // Handle the response of the second endpoint as needed
        console.log("Second POST request successful", secondResponse.data);
      }
    } catch (error) {
      console.error("Error submitting checked items:", error);
    }
  };

  return (
    <CCard>
      <CCardHeader>
        <div className="d-flex justify-content-between">
          <h3>Service Subcategory List</h3>
        </div>
      </CCardHeader>
      <CCardBody>
        {serviceitems && serviceitems.length > 0 ? (
          <CAccordion>
            {serviceitems.map((service, serviceIndex) => (
              <CAccordionItem key={serviceIndex}>
                <CAccordionHeader>
                  <span className="text-capitalize">
                    <strong>{service.service}</strong>
                  </span>
                </CAccordionHeader>
                <CAccordionBody>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Sno</th>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Visible</th>
                        <th>
                          <input
                            title="Select all"
                            type="checkbox"
                            checked={checkedItems[serviceIndex].every(Boolean)}
                            onChange={() => handleCheckAllChange(serviceIndex)}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {service.child.map((item, itemIndex) => (
                        <tr key={itemIndex}>
                          <td>{itemIndex + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.price}</td>
                          <td>{item.visible}</td>
                          <td>
                            <input
                              type="checkbox"
                              checked={
                                checkedItems[serviceIndex][itemIndex] || false
                              }
                              onChange={() =>
                                handleCheckboxChange(serviceIndex, itemIndex)
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CAccordionBody>
              </CAccordionItem>
            ))}
          </CAccordion>
        ) : (
          <tr>
            <th colSpan="6" scope="row">
              No record found
            </th>
          </tr>
        )}

        <CButton className="mt-4" color="dark" onClick={handleSubmit}>
          Submit Checked Items
        </CButton>
      </CCardBody>
    </CCard>
  );
}

export default ServiceToStore;
