import React, { useContext, useState } from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CForm,
  CCol,
  CFormSelect,
  CFormTextarea,
  CSpinner,
} from "@coreui/react";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import DataContext from "../components/Datacontext";
import { useEffect } from "react";
import {
  ERRORS,
  ITEM_EXISTS,
  CAT_SUBCAT,
  ERROR_DATA_UPDATE,
  DATA_ADDED,
  DATA_ADD_FAILED,
} from "../ERROR";
import useError from "../hooks/useError";

function ServiceList({ services, setFetchingOnDataAdd }) {
  const { store_id } = useContext(DataContext);
  const { auth } = useAuth();
  const [serviceData, setServiceData] = useState([]);
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState("pending");
  const [platform, setPlatform] = useState("website");
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState({});
  const access_token = auth.access_token;
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  const [isServiceAdding, setIsServiceAdding] = useState(false);
  const [addedCat, setAddedCat] = useState({});
  const [addedSubCat, setAddedSubCat] = useState({});

  const [categoryList, setCategoryList] = useState([]);
  const [serviceList, setServiceList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const { showError } = useError();

  useEffect(() => {
    isServiceAdding && fetchServiceList();
  }, [isServiceAdding]);

  useEffect(() => {
    isServiceAdding && fetchServiceDetail();
    setAddedSubCat({ subcategory: "", service_id: "", price: "" });
  }, [addedCat]);

  useEffect(() => {
    setServiceData(services);
  }, [services, isServiceAdding, addedCat]);

  const handleAddService = async () => {
    if (!(addedCat.id && addedSubCat.service_id)) {
      showError(CAT_SUBCAT, ERRORS.WARNING);
      return;
    }

    if (
      serviceData
        .map((service) => service.service_id)
        .some((id) => id === addedSubCat.service_id)
    ) {
      showError(ITEM_EXISTS, ERRORS.INFO);
      return;
    } else {
      setIsLoading(true);
      const newService = [
        {
          service: addedCat.service,
          ...addedSubCat,
          client_id: serviceData[0].client_id,
          store_id,
        },
      ];
      try {
        const response = await axios.post("/dailyservice/store", {
          data: newService,
        });
        response && showError(DATA_ADDED, ERRORS.SUCCESS);
      } catch (error) {
        showError(DATA_ADD_FAILED, ERRORS.ERROR);
      } finally {
        setIsServiceAdding(false);
        setFetchingOnDataAdd((prev) => !prev);
        setIsLoading(false);
      }
    }
  };

  const fetchServiceList = async () => {
    try {
      const response = await axios.get(`getallservices/${store_id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      setCategoryList(response.data.Services);
    } catch (err) {}
  };

  const fetchServiceDetail = async () => {
    try {
      const response = await axios.get(
        `servicespricelist/${store_id}/${addedCat.id}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setServiceList(response.data.ServicesPrice);
    } catch (err) {
      setServiceList([]);
    }
  };

  console.log(serviceList);

  const handleUpdateStatus = (e, id) => {
    e.preventDefault();

    const updateData = () => {
      try {
        axios
          .put(
            `/dailyservice/update/${data.id}`,
            {
              status,
              comment,
              platform,
              store_id,
            },
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
          .then((response) => {
            const updatedServiceData = serviceData.map((service) => {
              if (service.id === id) {
                return {
                  ...service,
                  status: response.data.DailyServices.status,
                };
              }
              return service;
            });

            setServiceData(updatedServiceData);
            setVisible(false);
            navigate("/login/clientservice");
          });

        // window.location.reload();
      } catch (err) {
        setValidated(true);
        showError(ERROR_DATA_UPDATE, ERRORS.ERROR);
      }
    };
    updateData();
  };

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Service Name</th>
            <th scope="col">Subcategory</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {serviceData &&
            serviceData.map((service, serviceIndex) => (
              <tr key={serviceIndex}>
                <th scope="row">{serviceIndex + 1}</th>
                <td>
                  <strong>{service.service}</strong>
                </td>
                {service?.subcategory ? (
                  <td>{service?.subcategory}</td>
                ) : (
                  <td></td>
                )}

                <td>{service.status}</td>

                <td>
                  <CButton
                    onClick={() => {
                      setVisible(!visible);
                      setData({
                        service_id: service.service_id,
                        id: service.id,
                        service: service.service,
                      });
                    }}
                    color="dark"
                  >
                    Action
                  </CButton>
                </td>
              </tr>
            ))}
          {isServiceAdding && (
            <tr>
              <th scope="row">{serviceData.length + 1}</th>
              <td>
                <select
                  name="category"
                  onChange={(e) =>
                    setAddedCat({
                      service: e.target.value,
                      id: e.target.options[e.target.selectedIndex].id,
                    })
                  }
                  value={addedCat.service}
                >
                  <option value="" defaultChecked>
                    Choose Category
                  </option>
                  {categoryList?.map((service) => (
                    <option
                      id={service.id}
                      key={service.id}
                      value={service.service}
                    >
                      {service.service}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  name="service"
                  onChange={(e) =>
                    setAddedSubCat({
                      subcategory: e.target.value,
                      service_id: e.target.options[e.target.selectedIndex].id,
                      price:
                        e.target.options[e.target.selectedIndex].dataset.pe,
                    })
                  }
                  value={addedSubCat.subcategory}
                >
                  <option value="" defaultChecked>
                    Choose Service
                  </option>
                  {serviceList?.map((service, index) => (
                    <option
                      data-pe={
                        service.store_price > 0
                          ? service.store_price
                          : service.price
                      }
                      id={service.id}
                      key={index}
                      value={service.name}
                    >
                      {service.name}
                    </option>
                  ))}
                </select>
              </td>
              <td>Pending</td>
              <td>
                <CButton
                  disabled={isLoading}
                  onClick={handleAddService}
                  size="sm"
                  color="dark"
                >
                  {isLoading ? <CSpinner color="light" size="sm" /> : "Add"}
                </CButton>
                <CButton
                  onClick={() => setIsServiceAdding(false)}
                  size="sm"
                  className="ms-2"
                  color="danger"
                >
                  Cancel
                </CButton>
              </td>
            </tr>
          )}
        </tbody>
        {!isServiceAdding && (
          <tfoot>
            <tr>
              <td>
                <button
                  onClick={() => {
                    setIsServiceAdding(true);
                  }}
                >
                  + Add Service
                </button>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tfoot>
        )}
      </table>

      <CModal
        size="lg"
        backdrop={"static"}
        alignment="center"
        scrollable
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <CModalHeader>
          <CModalTitle>Service Setting ({data.service})</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm
            onSubmit={(e) => handleUpdateStatus(e, data.id)}
            className="row g-3 needs-validation"
            noValidate
            validated={validated}
          >
            <CCol md={6}>
              <CFormSelect
                id="validationDefault02"
                label="Platform"
                onChange={(e) => setPlatform(e.target.value)}
                required
              >
                <option disabled>Choose...</option>
                <option value="website">Website</option>
                <option value="groupon">Groupon</option>
                <option value="google">Google</option>
                <option value="facebook">facebook</option>
                <option value="tiktok">Tiktok</option>
                <option value="yelp">Yelp</option>
                <option value="other">Other</option>
              </CFormSelect>
            </CCol>

            <CCol md={6}>
              <CFormSelect
                id="validationDefault03"
                label="Status"
                onChange={(e) => setStatus(e.target.value)}
                required
              >
                <option disabled>Choose...</option>
                <option value="pending">Pending</option>
                <option value="ongoing">Ongoing</option>
                <option value="completed">Completed</option>
                <option value="cancel">Cancel</option>
              </CFormSelect>
            </CCol>
            <CCol md={12}>
              <CFormTextarea
                id="service-detals"
                label="Service Details"
                rows={3}
                text="Must be 8-20 words long."
                onChange={(e) => setComment(e.target.value)}
              ></CFormTextarea>
            </CCol>

            <CCol xs={12}>
              <CButton color="dark" type="submit">
                Submit
              </CButton>
            </CCol>
          </CForm>
        </CModalBody>
      </CModal>
    </div>
  );
}

export default ServiceList;
