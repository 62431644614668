import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios";
import {
  CRow,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormFloating,
} from "@coreui/react";
import "./Service.css";
import Datacontext from "../components/Datacontext";
import Button from "../components/Globals/Button";
import {
  ERRORS,
  NO_DATA_ENTER,
  MISSING_CONTACT,
  UNAUTHORIZED,
  NOT_MEMBER,
  PHONE_LENGTH,
} from "../ERROR";

import useError from "../hooks/useError";

const ClientSignin = () => {
  const { updateClientData, setFwdContact } = useContext(Datacontext);
  const [validated, setValidated] = useState(false);
  const [phone_no, setNumber] = useState("");
  const navigate = useNavigate();

  const { showError } = useError();

  const handleNumberChange = (event) => {
    setNumber((prev) =>
      event.target.value.length <= 10
        ? event.target.value.replace(/\D/g, "")
        : prev
    );
  };
  const handleClientData = (data) => {
    updateClientData(data);
  };

  const haldelRegistration = async (e) => {
    if (phone_no.length !== 10) {
      showError(PHONE_LENGTH, ERRORS.ERROR);
      return;
    }
    try {
      const response = await axios.get("/client/getclient/" + phone_no, {
        phone_no,
      });
      const clientsdata = response.data.client;
      handleClientData(clientsdata);
      localStorage.setItem("clientsdata", JSON.stringify(clientsdata));
      navigate("/servicecard");
    } catch (err) {
      if (!err?.response) {
        showError(NO_DATA_ENTER, ERRORS.ERROR);
        setValidated(true);
      } else if (err.response?.status === 400) {
        showError(MISSING_CONTACT, ERRORS.ERROR);
      } else if (err.response?.status === 401) {
        showError(UNAUTHORIZED, ERRORS.ERROR);
      } else {
        showError(NOT_MEMBER, ERRORS.ERROR);
        navigate("/signup");
      }

      setFwdContact(phone_no);
    }
  };
  return (
    <>
      {/* <ToastContainer /> */}
      <CRow className="social-buttons">
        <CForm
          className="g-3 needs-validation"
          noValidate
          validated={validated}
        >
          <CRow>
            <CCol md={12} lg={12}>
              <CFormFloating className="mb-3">
                <CFormInput
                  type="text"
                  id="contactnumber"
                  placeholder="Mobile Number"
                  name="phone_no"
                  value={phone_no}
                  onChange={handleNumberChange}
                  autoComplete="number"
                  feedbackInvalid="Please provide a valid Nunber."
                  required
                />
                <CFormLabel htmlFor="contactnumber">Mobile Number</CFormLabel>
              </CFormFloating>
            </CCol>

            <CCol md={12} lg={12}>
              <div className="d-grid">
                <Button
                  options={{
                    size: "lg",
                    className: "py-3",
                    onClick: haldelRegistration,
                  }}
                >
                  Check In
                </Button>
              </div>
            </CCol>
          </CRow>
        </CForm>
      </CRow>
    </>
  );
};

export default ClientSignin;
