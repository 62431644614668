import { toast } from "react-toastify";

const useError = () => {
  const showError = (error, type = null) => {
    const defaultOptions = {
      autoClose: 3000,
      closeOnClick: true,
      pauseOnHover: false,
      pauseOnFocusLoss: false,
      position: "top-center",
    };

    type === "success" && toast.success(error, defaultOptions);
    type === "warn" && toast.warn(error, defaultOptions);
    type === "info" && toast.info(error, defaultOptions);
    type === "error" && toast.error(error, defaultOptions);
    type === null && toast(error, defaultOptions);
  };

  const clearError = () => toast.dismiss();

  return { showError, clearError };
};

export default useError;
